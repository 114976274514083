.ListOptions__Items{
    
}

.ListOptions__Item{
    border: 2px solid #ddd;
    transition: .3s ease all;
    cursor: pointer;
    border-radius: 3px;
    width: 150px;
}

.ListOptions__Item:hover{
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
}