
// Cambiando palette por css

.MuiButton-outlinedPrimary.Mui-disabled {
    color: #e58f8f !important;
    border-color: #e58f8f !important;
}

.MuiButton-containedPrimary.Mui-disabled {
    color: #fff !important;
    background-color: #e58f8f !important;
}

.MuiAccordionSummary-content.Mui-expanded{
    margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded{
    min-height: 40px !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--color-dark);
    outline: 0;
    box-shadow: none;
}

.form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--color-dark);
    outline: 0;
    box-shadow: none;
}

.form-control.is-invalid:focus,
.form-select.is-invalid:focus {
    box-shadow: none
}

.table {
    --bs-table-striped-bg: rgba(0, 0, 0, 0.02);
}

.form-check-input:focus {
    box-shadow: none;
    border-color: var(--color-dark);
}

.form-check-input:checked {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.table tbody tr td:last-child button:first-child {
    margin-left: 0 !important;
}

// Buttons

.btn {
    border-radius: 0.25rem;
}

.btn:focus {
    box-shadow: none !important;
}

.btn-primary {
    --bs-btn-bg: var(--color-primary) !important;
    background-color: var(--bs-btn-bg);
    border-color: var(--color-primary) !important;
    box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: var(--color-primary-2) !important;
    outline: 0 !important;
}

.btn-outline-primary {
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
    background-color: #fff !important;
}

.btn-outline-primary:hover {
    background-color: rgba(var(--color-primary-rgb), 0.05) !important;
}

.btn-secondary {

    background-color: var(--color-secondary);
    box-shadow: none !important;
    border-color: var(--color-secondary) !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: var(--color-secondary-hover) !important;
    outline: 0 !important;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
}

.btn-secondary:disabled {
    background-color: rgb(var(--color-secondary-rgb), 0.8) !important;
}

.btn-outline-secondary {
    border-color: var(--color-secondary-hover) !important;
    color: var(--color-secondary-hover) !important;
    background-color: #fff !important;
}

.btn-outline-secondary:hover {
    background-color: rgba(var(--color-secondary-2-rgb), 0.05) !important;
}


.btn-outline-white {
    border-color: #fff !important;
    color: #fff !important;
    background-color: transparent !important;
}

.btn-outline-white:hover {
    // background-color: rgba(var(--color-secondary-2-rgb), 0.05) !important;
}



// Dropdowns

.dropdown-item.active,
.dropdown-item:active {
    background-color: rgba(var(--color-primary-2-rgb)) !important;
    // color: var(--bs-dropdown-link-color);
}

// tables

.minByDropdown .table-responsive {
    min-height: 215px;
}

// Accordion

.accordion-button:not(.collapsed) {
    color: #333 !important;
    background-color: rgba(var(--color-secondary-2-rgb), 0.05) !important;

}

.accordion-button:focus {
    // border: 0 !important;
    box-shadow: none !important;
}

.Container__AccordionHeaderStatic>button {
    position: absolute;
    top: .9rem;
    right: 1rem;
    z-index: 20;
}

.Container__AccordionHeaderStatic .accordion-button::after {
    margin-left: 0;
}

.alert-secondary {
    background-color: #f4f4f4 !important;
}

// Form

.form-control.is-invalid {
    padding: 0.375rem 0.75rem !important;
    background-image: none !important;
}

.form-select.is-invalid {
    padding: 0.375rem 0.75rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.form-check-input.is-invalid~.form-check-label {
    color: rgb(33, 37, 41) !important;
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 2rem, 0);
        transform: translate3d(0, 2rem, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
}

.modal {
    // z-index: 1060;
    z-index: 1050;
}

// a {
//     color: var(--color-primary) !important;
// }

.btn-outline-white {
    background-color: transparent !important;
    color: #fff !important;
    border-color: #fff !important;
}

// Overwrite animate

.animate__fadeInLeftSmall {
    -webkit-animation-name: fadeInLeftSmall;
    animation-name: fadeInLeftSmall;
}

@-webkit-keyframes fadeInLeftSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-1rem, 0, 0);
        transform: translate3d(-1rem, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeftSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-1rem, 0, 0);
        transform: translate3d(-1rem, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInUpSmall {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
}

@-webkit-keyframes fadeInUpSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 1rem, 0);
        transform: translate3d(0, 1rem, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 1rem, 0);
        transform: translate3d(0, 1rem, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

// ------ MUI Modal fixed heigth

.MuiModal-root{
    overflow-y: auto !important;
    position: relative;
}

.MuiModal-root > .MuiGrid-root::after{
    content: '';
    position: absolute;
    // height: 5%;
    height: 4rem;
    width: 100%;
    // background-color: red;
    top: 100%;
    left: 0;
    z-index: -111;
}

.MuiBackdrop-root{
    position: sticky !important;
    height: 100%;
}

// ------------ Tooltip

.MuiTooltip-tooltip{
    background-color: #333 !important;
    font-weight: 400 !important;
    font-size: .9rem !important;
}