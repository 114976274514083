.BreadCrumbCustom{
    background-color: #fafafa !important;
    
    width: 100%;
    min-height: 56px;
    background-color: #FFF;
    border-bottom: 1px solid #ddd;
    padding: 0 50px;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    padding-left: 55px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    position: sticky;
    top: 54px;
    z-index: 9;
}

.BreadCrumbCustom ol {
    flex-wrap: nowrap !important;
}