
.NavigationSecondary {
    // width: 220px;
    // min-height: 100vh;
    // position: fixed;
    // position: absolute;
    // background-color: #fff;
    position: relative;
    z-index: 5;
    // top: 3.5rem;
    // padding: .5rem;
    padding-top: 0;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .14);
}

.NavigationSecondary ul{
    position: sticky;
    top: 4rem;
}

.NavigationSecondary ul a{
    padding: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    border-radius: 0.25rem;
    display: block;
    color: var(--bs-body-color);
    text-decoration: none;
}

.NavigationSecondary ul a:hover{
    background-color: #ddd;
}

.NavigationSecondary__li--active{
    background-color: #525252 !important;
    color: #FFF !important;
}

// .NavigationPromary__volver{
//     position: sticky;
//     bottom: .7rem;
//     text-align: center;
//     left: 0;
//     padding-top: .5rem;
//     padding-bottom: .5rem;
//     margin-top: auto;
//     border-radius: 0.25rem;
//     cursor: pointer;
//     margin-top: auto !important;
// }

