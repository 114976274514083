@import '../../../assets/scss/mixins.scss';

// ListConvenios

.ListConvenios{
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    background-color: #fff;
}

.ListConvenios img {
    display: block;
    margin: auto;
    max-width: 200px;
    max-height: 100px;

    @include min-lg {
        max-height: 80px;
        width: auto;
    }
}
