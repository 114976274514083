.UploadCard{
    flex: 1;
    margin: 0 !important;
}

.UploadCard__container{
    padding: 20px !important;
    flex: 1;
    background-color: #ffffff !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
}

.UploadCard__item{
    border: 1px dashed var(--color-primary);
    background-color: #fafafa;
    background-color: rgba(var(--color-primary-rgb),.07);
    cursor: pointer;
    /* margin: 15px; */
    transition: all .3s ease;
}

.UploadCard__item:hover {
    background-color: #fafafa;
    background-color: rgba(var(--color-primary-rgb),.15);
}