@import '../assets/scss/mixins.scss';

.PageDefault{
    width: 100%;
    min-height: 100vh;
}

.PageDefault__NavTop {

    background-color: #fff;
    color: #262626;
    padding-top: .6rem;
    padding-bottom: .6rem;
    position: absolute;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    z-index: 5;
    width: 100%;

    button{
        color: #262626;
    }
    

    @include min-lg {
        color: #262626;
    }

    a{
        color: #262626 !important;
        opacity: .8;
        text-decoration: none;
    }

    a:hover{
        opacity: 1;
    }
}

.PageDefault__NavTop img{
    height: 30px !important;
}

.PageDefault__contentbottom::before {
    content: "";
    background-color: #EAEAEA;
    height: 15rem;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}


.PageDefault__contentmain{
    padding-top: 6rem;
    z-index: 1;
    position: relative;
}