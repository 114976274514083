
.ListAutocomplete{
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    font-size: .9rem;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .ListAutocomplete li{
    cursor: pointer;
    padding: .4rem .5rem;
  }
  
  
  .ListAutocomplete li:hover{
    background-color: #efefef;
  }