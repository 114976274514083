@import '../../assets/scss/mixins.scss';

.PageLogin {
    background-color: #F5F5F5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#logo-login {
    width: 120px;
    // position: absolute;
    left: 1rem;
    top: 1.5rem;

    @include min-lg {
        width: 170px;
    }
}

#tabs-auth {
    .nav-link.active {
        color: #495057;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0;
        border-bottom: 2px solid var(--color-primary) !important;
        color: var(--color-primary) !important;
    }

    .nav-link {
        position: relative;
        z-index: 5;
        color: #8a8f94;
        border: 0;
        border-bottom: 2px solid #ddd;
    }

    li {
        font-size: .9rem;

        @include min-md {
            font-size: inherit;
        }
    }
}

.PageLogin__columnstart {
    order: 1;

    @include min-lg {
        order: 0;
    }
}

// PageLogin__Cards

.PageLogin__Cards{

    // border-top: 1px solid #ddd;
    background-image: url('../../assets/images/fondo2.jpg');
    flex: 1;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 55px;
}


.PageLogin__Cards::after{
    // background-color: rgba(var(--color-primary-rgb), 0.65);
    background-color: rgba(#c22225, .8);

    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 5;
}

// .PageLogin__Cards::before{
//     background-color: hsla(0,0%,100%,.88);
//     content: "";
//     height: 60%;
//     position: absolute;
//     width: 100%;
//     top: 40%;
//     z-index: 5;
// }

.PageLogin__CardsOverlay{
    transform: rotate(-3deg) translateZ(0);
    z-index: 7;
    position: absolute;
    background-color: #F5F5F5;
    // background-color: hsla(0,0%,100%,.88);
    outline: 1px solid transparent;

    top: 35%;
    width: 100%;
    height: 100%;
}

.PageLogin__CardsOverlay::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #F5F5F5;
    // background-color: hsla(0,0%,100%,.88);

    right: 99%;
    top: 0;
}

.PageLogin__CardsOverlay::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 99%;
    top: 0;
    background-color: #F5F5F5;
    // background-color: hsla(0,0%,100%,.88);

    z-index: 10;
}

.PageLogin__CardsCard--1{
    border-color: var(--color-primary) !important;
    // border: 2px solid var(--color-primary) !important;
}


.PageLogin__CardsCard{
    border: 1px solid #ddd;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
}

.PageLogin__CardsCard--title{
    padding: 1rem;
    text-align: center;
}

.PageLogin__CardsCard--descripcion{
    text-align: center;
    padding: 1rem;
    
}

.PageLogin__CardsCard--body{
    padding: 1rem 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #F7F7F7;
    flex: 1;
    font-weight: 400;
}

.PageLogin__CardsCard--body ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.PageLogin__CardsCard--body ul li{

    margin: 0;
    padding: .7rem 1rem;
    text-align: center;
}

.PageLogin__CardsCard--body ul li:nth-child(even){
    background-color: #efefef;
}

.PageLogin__CardsCard--footer{
    padding: 1rem;
}
