@import './mixins.scss';

.btn-transparent{
    color: var(--text-secondary);
    background-color: transparent;
    border: 0;
    line-height: 0;
  }

  
.fw-500 {
    font-weight: 500 !important;
  }
  
.fs-105{
  font-size: 1.05rem !important;
}

.fs-085{
  font-size: .85rem !important;
}

.fs-09{
  font-size: .9rem !important;
}

.me-25{
  margin-right: .75rem !important;
}

.min-vh-85 {
  min-height: 85vh !important;
}

.disabledCursor { 
  pointer-events: none;
}

.cursor-pointer{
  cursor: pointer;
}

.color-secondary{
  color: var(--color-secondary);
}

.color-primary{
  color: var(--color-primary);
}

.color-white{
  color: #fff !important;
}

.color-info{
  color: rgb(1, 67, 97);
}


.min-w-th-3{
  min-width: 250px;
}

.min-w-20rem{
  min-width: 20rem;
}

.max-w-20rem{
  max-width: 20rem;
}

.max-w-15rem{
  max-width: 15rem;
}

.max-w-6rem{
  max-width: 6rem;
}

.min-w-6rem{
  min-width: 6rem;
}

.max-w-300px{
  max-width: 300px;
}

.w-250px{
  width: 250px;
}

.min-w-auto{
  min-width: auto !important;
}

.flex-1{
  flex: 1;
}

.table-shrink-th thead th:last-child{
  width: 0.1%;
  white-space: nowrap;
}

.shrink-td {
  width: 0.1%;
  white-space: nowrap;
}

.text-nowrap{
  white-space: nowrap;
}

.table-orderby-active{
  color: var(--color-primary);
  font-weight: bold;
}

a.link-gray{
    color: #333;
}

a.link-gray:hover{
    color: var(--bs-link-color);
}

.bg-FAFAFA{
  background-color: #FAFAFA;
}

.bg-fafafa{
  background-color: #fafafa !important;
}

.bg-gray-1{
  background-color: #f8f8f8 !important;
}

.tr-red{
  background-color: #ffebeb !important;
}

.tr-green{
  background-color: #ebfff3 !important;
}

.tr-td-transparent td{
  --bs-table-accent-bg: transparent !important;
}

// .fade.modal-backdrop.show {
//   z-index: 1060;
// }

// .modal{
//   z-index: 1060;
// }

.hover-text-decoration:hover{
  text-decoration: underline !important;
}

.color-danger{
  color: #842029;
}

.card-danger{
  border: 1px solid #ffd2d6;
  background-color: #fffafb;
}

.modal-70vw{
  width: 70vw;    /* Occupy the 90% of the screen width */
  max-width: 70vw;
} 

@include min-xxl{
  .pb-lg-8{
    padding-bottom: 8rem !important;
  }
}

.z-index-10{
  z-index: 10;
}

.list-unstyled{
  list-style: none;
  padding: 0;
  margin: 0;
}

.color-green{
  color: rgb(29 196 1)
}

.color-red{
  color: rgb(196, 40, 1)
}


.input-text-center input{
  text-align: center;
}

.line-dashed {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  border-bottom: 1px dashed #707070;
}

.scroll-smooth{
  scroll-behavior: smooth;
}

.fs-1-1rem{
  font-size: 1.1rem !important;
}

.hover-opacity-1:hover{
  opacity: 1 !important;
}


.labelFocusAlways label{
  transform: translate(0, -1.5px) scale(0.75);
}

.tr-invisible .btn-historico{
  visibility: hidden;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.link-primary {
  color: var(--color-primary) !important;
  text-decoration: none;
  cursor: pointer;
}

.link-primary:hover, .link-primary:focus {
  color: var(--color-primary-2) !important;
  text-decoration: underline;
}

.flex-basis-0{
  flex-basis: 0 !important;
}

.color-error{
  color: #d32f2f;
}

.color-success{
  color: #46934b
}

.border-bottom-2{
  border-bottom: 2px solid #c0c0c0 !important;
}

.max-w-430px{
  max-width: 430px;
}

.max-w-370px{
  max-width: 370px;
}

.max-w-500px{
  max-width: 500px;
}


.border-primary{
  border-color: var(--color-primary) !important;
}

.text-decoration-underline-hover:hover{
  text-decoration: underline !important;
}

.tr-last-hidden td:last-child div{
  opacity: 0;
  visibility: hidden;
}