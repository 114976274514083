// .DropdownNotifications {

    .DropdownNotifications__pendiente{

        // background-color: rgba(var(--color-primary-rgb), .05);
    }
// }

.DropdownNotifications__Information{
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: var(--color-primary);
    border-radius: 100%;
    color: #fff;
    font-weight: normal;
    left: -22px;
    top: -9px;
}