.TagTopProducto{
    position: relative;
    padding-top: 1rem;
}

.TagTopProducto__title{
    position: absolute;
    background-color: #F7F7F7;
    padding: .5rem 1rem;
    border: 1px solid var(--color-primary);
}