table tbody tr:hover {
    background-color: rgba(225,231,240,.2);
    background-color: var(--light-20);
    --bs-table-accent-bg: var(--light-20);
}

table thead {
    background-color: #ededed;
}

.TableAdvanced{
    td{
        padding-top: .7rem;
        padding-bottom: .7rem;
    }

    th{
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
}

.AllActionsPerLine__ContainerButton:first-child{
    margin-left: 0 !important;
}

.TableAdvancedHead__AllActions{

    margin-left: auto !important;
}