.LayoutLibro{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #f6f6f6;
}

.LayoutLibro__navleft--container{
    transition: .3s ease all;

}

.LayoutLibro__navleft{
    width: 260px;
    /* border-right: 1px solid #ddd; */
    height: 100%;
    position: fixed;
    background-color: #F6F6F6;
    left: -100%;
    transition: .3s ease all;
    z-index: 20;
}

.LayoutLibro__navleft--active .LayoutLibro__navleft{
    left: 0;
}

#LayoutLibro__navleft--backdrop{
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: rgb(0 0 0 / 46%);
    transition: .3s ease all;
    display: none;
    z-index: 13;
}

.LayoutLibro__navleft--active #LayoutLibro__navleft--backdrop{
    opacity: 1;
    display: block;
}

@media (min-width: 768px){
    .LayoutLibro__navleft{
        width: 220px;
        height: 100%;
        left: 0;
        z-index: 10;

    }
}

.LayoutLibro__navleft img{
    margin: 0 auto;
    padding-top: 1.2rem;
    display: block;
}

.LayoutLibro__navmenu{
    position: relative;
    bottom: 0;
    padding: 1rem 1.4rem;
    /* border-top: 1px solid #ddd; */
}

.LayoutLibro__contentright{
    width: 100%;
    /* float: right; */
    // background-color: #fff;
    z-index: 10;
    /* min-height: 100vh; */
    // box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 14%);
    position: relative;
    transition: .3s ease all;
    padding-top: 7.5rem;

}

@media (min-width: 768px){
    .LayoutLibro__contentright{
        /* width: calc(100% - 220px); */
        // padding-left: 1.5rem;
        // padding-right: 1.5rem;
        z-index: 20;
    }
}

.LayoutLibro__NavTop{
    /* position: sticky; */
    top: 0;
    background-color: #fff;
    background: #f6f6f6;
    /* height: 100px; */
    width: 100%;
    /* border-bottom: 1px solid #ddd; */
    padding-left: .3rem;
    padding-right: .3rem;
    z-index: 5;
    height: 54px;

    height: 3.5rem;
    width: 100%;
    background-color: #262626;
    position: fixed;
    color: #fff;
    z-index: 15;
}

.LayoutLibro__navtop{
    /* position: sticky; */
    top: 0;
    background-color: #fff;
    /* background: #f6f6f6; */
    /* height: 100px; */
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: .3rem;
    padding-right: .3rem;
    z-index: 5;
    height: 90px;
}

.LayoutLibro__NavTop img{
    height: 30px !important;
}

.LayoutLibro__contentmain{
    // padding-top: .7rem;
    display: flex;
}

.LayoutLibro__navlinks{
    margin-top: 1rem;
}

.LayoutLibro__navlinks a{
    padding: 0.6rem 2rem;
    text-decoration: none;
    /* color: hsla(0,0%,100%,.6); */
    color: #333;
    margin: .8rem 0;
    transition: ease all .3s;
}

@media (min-width: 768px){
    .LayoutLibro__navlinks a{
        padding: 0.6rem 1.4rem;
    }
}


.LayoutLibro__navlinks a:hover,
.LayoutLibro__navlinks a.active{
    color: var(--bg-primary-2);
}


.LayoutLibro__navmenu:hover {
    color: var(--bg-primary-2);
    cursor: pointer;
}

// #btn_show_filter_advance{
//     opacity: .8;
//     transition: .3s ease all;
// }

// #btn_show_filter_advance:hover{
//     cursor: pointer;
//     opacity: 1;
// }

/* Responsive */

.LayoutLibro__NavTop {
    height: 3.5rem;
    width: 100%;
    background-color: #fff;
    position: fixed;
    color: #262626;
    z-index: 25;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

}

.LayoutLibro__NavTop button{
    color: #262626;
}


.LayoutLibro__NavTop2 {
    background-color: #FBD866;
    background-color: #FFF;
    width: 100%;
    height: 4rem;
    position: absolute;
    z-index: 23;
    // top: 3.5rem;
    border-bottom: 1px solid #b9b9b9;
    top: 3.5rem;
}

.LayoutLibro__NavTop2__nav{
    padding-top: 1.7rem;
    height: 100%;
    height: auto;
    position: relative;
    bottom: -1px;
}

.LayoutLibro__NavTop2__nav a{
    margin-left: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--bs-body-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.LayoutLibro__NavTop2__nav--active{
    // background-color: #f6f6f6;
    // border-left: 1px solid #b9b9b9;
    // border-top: 1px solid #b9b9b9;
    // border-right: 1px solid #b9b9b9;
    color: var(--color-primary) !important;
    border-bottom: 2px solid var(--color-primary)!important;
}

.LayoutLibro__NavTop2--title{
    color: var(--color-primary) !important;

}


.NavigationPromary__volver{
    position: sticky;
    bottom: .7rem;
    text-align: center;
    left: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: auto;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: auto !important;
}


.Main {
    width: calc(100% - 220px);
    background-color: #f6f6f6;
    // top: 3.5rem;
    float: right;
    // min-height: 100vh;
    // min-height: 160vh;
    position: relative;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
}
