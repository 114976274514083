.ImageCard{
    box-shadow: none;
    /* transition: ease all .3s; */
    position: relative;
    top: 0;
    overflow: hidden;
}

.ImageCard > div {
    /* border: 1px solid #ddd; */
    border-radius: 3px;
    height: 14rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    border-radius: 0.4rem;
    overflow: hidden;
}

.ImageCard img {
    /* object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; */
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.ImageCard__content {
    /* background-color: rgb(0 0 0 / 20%); */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    padding: 1rem;
    /* transition: ease all .3s; */
}

.ImageCard:hover .ImageCard__content{
    background-color: rgb(0 0 0 / 40%);
}

.ImageCard:hover{
    /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important; */
    /* top: -5px; */
}

.ImageCard__options{
    position: absolute !important;
    right: .7rem;
    top: 2rem;
}

.ImageCard__option{
    z-index: 10;
    margin-bottom: 1rem !important;
    border: 0 !important;
    background-color: #fff !important;
    display: none !important;
    /* transition: ease all .3s; */
}

.ImageCard__option:hover{
    background-color: #ddd !important;
}

.ImageCard:hover .ImageCard__option{
    /* right: .7rem; */
    display: block !important;

}

.ImageCard__fondo{
    background: #98f0ff;
    height: 100%;
}