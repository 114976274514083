
// DropdownAdvanced

.DropdownAdvanced > button{
    background-color: transparent !important;
    border: 0 !important;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--color-primary);
}

.DropdownAdvanced__title{
    cursor: pointer;
}