
.LayoutDescConstNavTop{
    border-bottom: 1px solid #ddd;
}

.LayoutDescConstNavTop a {
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--bs-body-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.LayoutDescConstNavTop__nav--active {
    color: var(--color-primary) !important;
    border-bottom: 2px solid var(--color-primary) !important;
}