.AccordionAdvancedPM{

}


.AgregarPM__circle svg{

    font-size: 1.3rem !important;
    display: none;
    transition: .3s ease all;
}

.TreeView__Parent:hover .AgregarPM__circle svg{

    display: block;
    color: var(--color-primary) !important;
}

.Tree__label{
    display: flex;
    justify-content: space-between;
    align-items: center;
}