
.NavBottom {
    // width: 220px;
    // min-height: 100vh;
    // position: fixed;
    // position: absolute;
    // background-color: #fff;
    position: relative;
    z-index: 5;
    
    // padding: .5rem;
    padding-bottom: 1.5rem;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .14);
}

.NavBottom ul{
    position: sticky;
    // top: 4rem;
    display: flex;
    
    margin-bottom: 0;
}

.NavBottom ul a{
    padding: .8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    // border-radius: 0.25rem;
    display: block;
    color: var(--bs-body-color);
    text-decoration: none;
}

@media (max-width: 575.98px) {

    .NavBottom ul a{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: .8rem;
        padding-left: .4rem;
        padding-right: .4rem;
    
    }
}

.NavBottom ul a:hover{
    // background-color: #ddd;
}

.NavBottom__li--active{
    // background-color: #525252 !important;
    color: var(--color-primary) !important;
    border-bottom: 2px solid var(--color-primary);
}

// .NavigationPromary__volver{
//     position: fixed;
//     bottom: 0;
//     text-align: center;
//     width: 205px;
//     left: 0;
//     padding-top: .5rem;
//     padding-bottom: .5rem;
//     margin: .5rem;
//     border-radius: 0.25rem;
//     cursor: pointer;
// }

