
.TableTreeAdvanced__IconDrag{
    width: 24px;
    height: 100%;
    position: relative;
    // transition: .3s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8d8d8d;
    background-color: #eeeeee;
    position: absolute;
    opacity: .5;
    top: 0;
    left: 0;
}

.TableTreeAdvanced__IconDrag:hover{
    cursor: grab;
    background-color: #eeeeee;
    opacity: 1;
}