
.LayoutProgramacionGestionNavTop{
    border-bottom: 1px solid #ddd;
}

.LayoutProgramacionGestionNavTop a,
.LayoutProgramacionGestionNavTo__a {
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--bs-body-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
}

.LayoutProgramacionGestionNavTo__a{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.LayoutProgramacionGestionNavTop__nav--active {
    color: var(--color-primary) !important;
    border-bottom: 2px solid var(--color-primary) !important;
}



.LayoutFasesNavTop__navStartArrowTop{
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(35deg);
    background-color: #fff;
    border: 2px solid #ddd !important;
    box-sizing: border-box;
    border-bottom: 0 !important;
}

.LayoutFasesNavTop__navStartArrowBottom{
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: skew(-35deg);
    background-color: #fff;
    border: 2px solid #ddd !important;
    box-sizing: border-box;
    border-top: 0 !important;
}

.LayoutFasesNavTop__navStart--active{
    color: var(--color-primary) !important;
    z-index: 5;
}

.LayoutFasesNavTop__navStart--active .LayoutFasesNavTop__navStartArrowTop,
.LayoutFasesNavTop__navStart--active .LayoutFasesNavTop__navStartArrowBottom{
    background-color: #fff;
    
}

.LayoutFasesNavTop__navStart--active .LayoutFasesNavTop__navStartArrowTop{
    background-color: #fff;
    border: 2px solid var(--color-primary) !important;
    border-bottom: 0 !important;
    
}

.LayoutFasesNavTop__navStart--active .LayoutFasesNavTop__navStartArrowBottom{
    background-color: #fff;
    border: 2px solid var(--color-primary) !important;
    
    border-top: 0 !important;
}

.LayoutFasesNavTop__navStartText{
    position: relative;
    z-index: 5;
}