#btn_show_filter_advance{
    position: relative;
    z-index: 11;
    // background-color: #ddd;
}

#btn_show_filter_advance{
    opacity: .6;
    transition: .2s ease all;
    cursor: pointer;
}

#btn_show_filter_advance:hover{
    opacity: 1;
}