:root {
    --color-primary: #E94B4C;
    --color-primary-rgb: 233, 75, 76;

    --color-primary-2: #bc2b2b;
    --color-primary-2-rgb: 188, 43, 43;

    --color-secondary: #010100;
    --color-secondary-rgb: 1, 1, 0;

    --color-secondary-2: #474747;
    --color-secondary-2-rgb: 71, 71, 71;

    // --bg-secondary: #FD8A19;
    // --bg-secondary-rgba: 253, 138, 25;
    // --bg-secondary-hover: #e57d16;
    // --bg-secondary-hover-rgba: 229, 125, 22;

    // --bs-btn-bg: #2FAEC7;
    // --bs-link-color: #2FAEC7;

    // --light-20: rgba(225,231,240,0.2);
}


/* Imports Fonts */

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Air.otf') format('opentype');
    font-weight: air;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Thin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: Aeonik;
    src: url('../../assets/fonts/Aeonik-Black.otf') format('opentype');
    font-weight: 900;
}

body {
    font-family: Aeonik, sans-serif;
    background-color: #f2f4f6;
    margin: 0;
}