
.InputImages__container .box-input-file-elem.isDragOver{
    /* content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: yellow;
    box-shadow: 0px 0px 10px yellow;
    left: -5px; */
    /* margin-left: 5rem !important; */


    /* background-color: transparent !important;
    box-shadow: none !important; */
    
}


.InputImages__Containerloader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffffb3;
}