
.LayoutPotencialMejoraNavTop{
    border-bottom: 1px solid #ddd;
    // background-color: #fff;
}

.LayoutPotencialMejoraNavTop a {
    // margin-left: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #565656;
    text-decoration: none;
    margin-top: .7rem;
    margin-bottom: .7rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    // border: 2px solid red;
    position: relative;
}

.LayoutPotencialMejoraNavTop__nav{
    box-sizing: border-box;
}

.LayoutPotencialMejoraNavTop__nav--active {
    color: var(--color-primary) !important;
    // border-bottom: 2px solid var(--color-primary) !important;
    // border-left: 2px solid var(--color-primary) !important;
    // border-top: 2px solid var(--color-primary) !important;
    position: relative;
    // background-color: #fff;
    z-index: 20;
}

.LayoutPotencialMejoraNavTop__navArrowTop{
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(35deg);
    background-color: #fff;
    border: 2px solid #ddd !important;
    box-sizing: border-box;
    border-bottom: 0 !important;
}

.LayoutPotencialMejoraNavTop__navArrowBottom{
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: skew(-35deg);
    background-color: #fff;
    border: 2px solid #ddd !important;
    box-sizing: border-box;
    border-top: 0 !important;
}

.LayoutPotencialMejoraNavTop__nav--active .LayoutPotencialMejoraNavTop__navArrowTop,
.LayoutPotencialMejoraNavTop__nav--active .LayoutPotencialMejoraNavTop__navArrowBottom{
    background-color: #fff;
    
}

.LayoutPotencialMejoraNavTop__nav--active .LayoutPotencialMejoraNavTop__navArrowTop{
    background-color: #fff;
    border: 2px solid var(--color-primary) !important;
    border-bottom: 0 !important;
    
}

.LayoutPotencialMejoraNavTop__nav--active .LayoutPotencialMejoraNavTop__navArrowBottom{
    background-color: #fff;
    border: 2px solid var(--color-primary) !important;
    
    border-top: 0 !important;
}

.LayoutPotencialMejoraNavTop__navText{
    position: relative;
    z-index: 5;
}