.InputImage__input{
    padding: 15px !important;
    flex: 1;
    margin: 0 !important;
}

.InputImage__container{
    padding: .7rem !important;
    flex: 1;
    background-color: #ffffff !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
}

.InputImages__container{
    display: grid;
    grid-template-columns: 1fr;
    /* gap: 1em; */
}

@media (min-width: 576px){
    .InputImages__container{
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px){
    .InputImages__container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.InputImages__container .box-input-file-elem:hover{
    cursor: grab;
}

.InputImages__container .box-input-file-elem{
    position: relative;
}

.InputImages__container .box-input-file-elem.isDragOver{
    /* content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: yellow;
    box-shadow: 0px 0px 10px yellow;
    left: -5px; */
    /* margin-left: 5rem !important; */
    background-color: transparent !important;
    box-shadow: none !important;
    
}

.InputImages{
    position: relative;
}

.InputImages__Containerloader{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffffb3;
}

.InputFile__ContainerName {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.InputChecks label{
    align-self: flex-start;
    padding-right: 16px;
}