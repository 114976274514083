.active>.page-link, .page-link.active{
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.page-link{
    color: #333;
}

.page-link:focus{
    box-shadow: none;
    color: var(--color-secondary);
}