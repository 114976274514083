
.content-tag-view-admin{
    height: 3.5rem;
    background-color: var(--color-primary);
    margin-right: -1.3rem;
    transform: skew(-16deg, 0deg);
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1.3rem;
    margin-left: 0.5rem;
    transition: .3s ease all;
}

.content-tag-view-admin .color-white{
    transform: skew(16deg, 0deg);
}

.content-tag-view-admin:hover{
    cursor: pointer;
    background-color: rgba(var(--color-primary-rgb), .7);

}