
.MenuList__li{
    border-left: 3px solid transparent;
    padding: .5rem .8rem;
    cursor: pointer;
    transition: .2s ease all;
    font-size: 1.1rem;
    margin-bottom: .5rem;
}

.MenuList__li--active{
    border-left: 3px solid var(--color-primary);
    color: var(--color-primary);
    font-weight: bold;
}

.MenuList__li:hover{
    color: var(--color-primary);
}
