
.NavigationPrimary {
    width: 220px;
    // min-height: 100vh;
    // position: fixed;
    // position: absolute;
    background-color: #fff;
    position: relative;
    z-index: 5;
    // top: 3.5rem;
    padding: .5rem;
    padding-top: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .14);
    // height: calc(100vh - 12.3rem);
    // height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.NavigationPrimary::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #fff;
}

.NavigationPrimary::-webkit-scrollbar
{
	width: 6px;
	background-color: #fff;
}

.NavigationPrimary::-webkit-scrollbar-thumb
{
    border-radius: 4px;
	background-color: #ddd;
}



.NavigationPrimary ul{
    position: sticky;
    top: 4rem;
}

.NavigationPrimary ul a{
    padding: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    border-radius: 0.25rem;
    display: block;
    color: var(--bs-body-color);
    text-decoration: none;
}

.NavigationPrimary ul a:hover{
    background-color: #ddd;
}

.NavigationPrimary__li--active{
    background-color: #525252 !important;
    color: #FFF !important;
}

