.Footer{
    background-color: #212121;
    position: relative;
    z-index: 111;
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #fff;

    button,
    a{
        color: #fff;
        text-decoration: none;
    }
    
    
}
