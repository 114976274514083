.MuiModal-root{
    
}

.ModalHeader{
    position: relative;
    // background-color: green;
}

.ModalHeader__containericonclose{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    // right: -12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    // background-color: red;
}


.ModalHeader__containericonclose svg{
    transition: .1s ease all;
    font-size: 1.4rem;
}

.ModalHeader__containericonclose:hover svg{
    color: #555;
}