.MetricasTreeView .Mui-selected{
    background-color: transparent !important;
    color: var(--color-primary);
    font-weight: bold !important;
}

.MetricasTreeView .MuiTreeItem-content{
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.MetricasTreeView svg{
    font-size: .9rem !important;
}