@import '../../assets/scss/mixins.scss';

.PageIndex {
    overflow: hidden;
    background-color: #fff;
}

.PageIndex__NavTop {

    background-color: #fff;
    color: #262626;
    padding-top: .6rem;
    padding-bottom: .6rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    position: fixed;
    top: 0;
    z-index: 60;
    width: 100%;

    button {
        // color: #fff;
    color: #262626;

    }


    @include min-lg {
        // color: #fff;
    color: #262626;

    }

    a {
        // color: #fff !important;
    color: #262626;
    opacity: .8;
        text-decoration: none;
    }

    a:hover {
        opacity: 1;
    }
}

.PageIndex__NavTop img {
    height: 30px !important;
}

.PageIndex__CTA {
    height: auto;
    background-image: url('../../assets/images/fondo2.jpg');
    background-position: center;
    background-size: cover;

    position: relative;

    z-index: 10;
    padding-top: 55px;

    @include min-md {
        height: 50vh;
    }

    @include min-lg {
        height: 65vh;
    }
}

.PageIndex__CTA--text {
    z-index: 10;
    position: relative;
}

.PageIndex__CTA--text h4 {

    font-size: 1.5rem;

    @include min-lg {
        font-size: 2.125rem;
    }
}

.PageIndex__CTA--text p {

    font-size: 1rem;

    @include min-lg {
        font-size: 1.25rem;
    }
}

.PageIndex__CTA::after {
    content: '';
    background-color: rgba(#c22225, .8);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
}

.PageIndex__Beneficios {
    transform: rotate(-3deg) translateZ(0);
    z-index: 51;
    position: relative;
    background-color: #fff;
    top: -60px;
    outline: 1px solid transparent;
}

.PageIndex__Beneficios::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 99%;
    top: 0;
    background-color: #fff;
    z-index: 10;
}

.PageIndex__Beneficios::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    right: 99%;
    top: 0;
}

.PageIndex__Beneficios--container {
    transform: rotate(3deg) translateZ(0);
    outline: 1px solid transparent;
}

.PageIndex__Beneficios img {
    width: 300px;
    position: relative;
    z-index: 15;
    margin-left: auto;
    margin-right: auto;
    display: block;
    // background-color: var(--color-primary);
    // border: 5px solid #000;

    @include min-lg {
        margin-top: -10%;
        width: 75%;
        margin-left: 0;
        margin-right: 0;
    }
}

.PageIndex__Beneficios--text {
    @include min-lg {
        margin-top: 4.2rem !important;
    }
}

.PageIndex__Beneficios--dot {
    // padding: 1rem;
    background-color: #000;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
}

.PageIndex_Ventajas {
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #f2f4f6;
    position: relative;
    z-index: 20;
    border-top: 1px solid #ddd;
}

// PageIndex__Pasos

.PageIndex__Pasos {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #fff;
    position: relative;
    z-index: 20;
    border-top: 1px solid #ddd;
}

.PageIndex__Pasos img {
    width: 100%;
    width: 235px;
}


.PageIndex__Pasos--dot {
    // padding: 1rem;
    background-color: var(--color-primary);
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
}

// PageIndex__Productos

.PageIndex__Productos {
    background-color: #f2f4f6;
    border-top: 1px solid #ddd;
}

.PageIndex__ProductosCard--1 {
    border-color: var(--color-primary) !important;
    // border: 2px solid var(--color-primary) !important;
}


.PageIndex__ProductosCard {
    border: 1px solid #ddd;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-color: var(--color-primary) !important;

}

.PageIndex__ProductosCard--title {
    padding: 1rem;
    text-align: center;
}

.PageIndex__ProductosCard--descripcion {
    text-align: center;
    padding: 1rem;

}

.PageIndex__ProductosCard--body {
    padding: 1rem 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #F7F7F7;
    flex: 1;
    font-weight: 400;
}

.PageIndex__ProductosCard--body ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: .95rem;
}

.PageIndex__ProductosCard--body ul li {

    margin: 0;
    padding: .7rem 1rem;
    text-align: center;
    border-top: 1px solid #ddd;
}

.PageIndex__ProductosCard--body ul li:first-child {
    border-top: 0;

}

// .PageIndex__ProductosCard--body ul li:nth-child(even){
//     background-color: #efefef;
// }

.PageIndex__ProductosCard--footer {
    padding: 1rem;
}

// PageIndex__Programas

.PageIndex__Programas {
    padding-top: 5rem;
    background-color: #f2f4f6;
    padding-bottom: 3rem;
}

.order-1-image {

    order: 1;

    @include min-lg {
        order: 0;
    }
}

.border-iniciar-sesion {

    border: 0;

    @include min-lg {

        border-left: 1px solid #fff ;
    }
}